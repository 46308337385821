<template>
  <div class="container">
    <BasicTitle :title="$route.name" />
    <BasicSubtitle subtitle="Los campos señalados con (*) son obligatorios." />
    <div class="container-form">
      <BasicLabel label="Nombre *" />
      <BorderInput
        v-model="resident.data.name"
        label="Nombre *"
        @keyup.enter="onSave"
      />
      <FormError :show="resident.rules.name" message="Ingrese un nombre" />
      <BasicLabel label="CI *" />
      <BorderInput
        v-model="resident.data.identity"
        label="CI *"
        @keyup.enter="onSave"
      />
      <FormError
        :show="resident.rules.identity"
        message="Ingrese un número de documento válido sin espacios, puntos y guiones"
      />
      <BasicLabel label="Correo *" />
      <BorderInput
        v-model="resident.data.email"
        label="Correo *"
        @keyup.enter="onSave"
      />
      <FormError
        :show="resident.rules.email"
        message="Ingrese un email válido"
      />
      <BasicLabel label="Teléfono *" />
      <div class="pd-flex">
        <BorderInputPrefix label="+598" />
        <BorderInputPhone
          v-model="resident.data.phone"
          label="Teléfono *"
          @keyup.enter="onSave"
        />
      </div>
      <FormError
        :show="resident.rules.phone"
        message="Ingrese un teléfono válido"
      />
    </div>
    <PrimaryButton label="Guardar" :click="onSave" />
  </div>
</template>

<script>
import BasicTitle from "../../widgets/title/BasicTitle";
import BasicLabel from "../../widgets/label/BasicLabel";
import BasicSubtitle from "../../widgets/subtitle/BasicSubtitle";
import BorderInput from "../../widgets/input/BorderInput";
import BorderSelect from "../../widgets/select/BorderSelect";
import BorderInputPrefix from "../../widgets/input/BorderInputPrefix";
import BorderInputPhone from "../../widgets/input/BorderInputPhone";
import FormError from "../../widgets/error/FormError";
import PrimaryButton from "../../widgets/button/PrimaryButton";
import store from "../../store";
import { onBeforeMount, onBeforeUnmount, reactive, watchEffect } from "vue";
import { useRouter, useRoute } from "vue-router";
import { openMode, validation, actions } from "../../constants";

export default {
  components: {
    BasicTitle,
    BasicLabel,
    BasicSubtitle,
    BorderInput,
    BorderSelect,
    BorderInputPrefix,
    BorderInputPhone,
    FormError,
    PrimaryButton,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const resident = reactive({
      residentType: [
        { id: "I", name: "Inquilino" },
        { id: "P", name: "Propietario" },
      ],
      data: {
        id: "",
        name: "",
        identity: "",
        email: "",
        phone: "",
      },
      rules: {
        name: false,
        identity: false,
        email: false,
        phone: false,
      },
    });

    const validate = () => {
      let valid = true;

      if (!resident.data.name) {
        resitend.rules.name = true;
        valid = false;
      }
      if (
        !resident.data.identity ||
        !validation.validateCI(resident.data.identity)
      ) {
        resitend.rules.identity = true;
        valid = false;
      }
      if (
        !resident.data.email ||
        !validation.emailRegex.test(resident.data.email)
      ) {
        resitend.rules.email = true;
        valid = false;
      }
      if (
        !resident.data.phone ||
        !validation.phoneRegex.test(resident.data.phone)
      ) {
        resitend.rules.phone = true;
        valid = false;
      }

      return valid;
    };

    const onSave = async () => {
      if (!validate()) return;

      store.commit("setLoading", true);

      const request = {
        buildingId: store.state.general.selectedBuilding,
        id: resident.data.id,
        name: resident.data.name,
        identity: resident.data.identity,
        email: resident.data.email,
        phone: `+598 ${resident.data.phone}`,
      };

      let response = null;
      if (store.state.openMode === openMode.CREATE) {
        response = await store.dispatch(
          actions.residentActions.create,
          request
        );
      }
      store.commit("setLoading", false);
      if (response.ok) {
        store.commit("setSelectedResidents", []);
        router.push("/residentes/listado");
      } else {
        store.state.toast.add({
          severity: "error",
          summary: "",
          detail: response.message,
          life: 5000,
        });
      }
    };

    onBeforeMount(() => {
      if (!store.state.openMode) router.push("/residentes/listado");
      store.commit("addBreadcrumb", { label: route.name, to: route.path });
      /* if (store.state.openMode === openMode.MODIFY) {
        const selected = store.state.resident.selectedResidents[0];
        resident.id = selected.id;
        resident.name = selected.name;
        resident.identity = selected.identity;
        resident.email = selected.email;
        resident.phone = selected.phone ? selected.phone.split(" ")[1] : "";
        resident.indOwner = selected.indOwner;
      } */
    });

    onBeforeUnmount(() => {
      store.commit("removeBreadcrumb");
    });

    watchEffect(() => {
      if (resident.data.name) resident.rules.name = false;
      if (
        resident.data.identity &&
        validation.validateCI(resident.data.identity)
      )
        resident.rules.identity = false;
      if (
        resident.data.email &&
        validation.emailRegex.test(resident.data.email)
      )
        resident.rules.email = false;
      if (
        resident.data.phone &&
        validation.phoneRegex.test(resident.data.phone)
      )
        resident.rules.phone = false;
    });

    return {
      store,
      resident,
      onSave,
    };
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;
}
</style>
